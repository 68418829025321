/* This page is created automatically in create_pages.sh
 * Do not edit this file directly
 */

import React from "react"
import TabPageLayout from "../layout/tab-page"

export default function TabPage(props) {
  return <TabPageLayout tab="donate" />
}
